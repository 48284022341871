import classNames from 'classnames';
import React from 'react';

import { ArrowRightIcon } from '@jernia/shared/components/icons/icons';

import { JerniaLink } from '../jernia-link';

export type Breadcrumb = {
  name: string;
  url?: string;
  storefrontUrl?: string;
  onClick?: () => void;
};

type Props = {
  breadcrumbs: Breadcrumb[];
  small?: boolean;
  className?: string;
};

// @TODO: Use Next <Link /> conditionally

export function Breadcrumbs({ breadcrumbs, small, className }: Props) {
  return (
    <div className="overflow-x-auto">
      <div
        className={classNames('flex items-center text-sm font-semibold', {
          'sm:text-lg md:text-xl': !small,
          'sm:text-base': small,
        })}
      >
        {breadcrumbs.map((breadcrumb, i) => {
          const last = i === breadcrumbs.length - 1;
          return (
            <React.Fragment key={i}>
              <>
                {breadcrumb.url ? (
                  <JerniaLink href={breadcrumb.url}>
                    <a className="whitespace-nowrap text-primary hover:text-text-800">
                      {breadcrumb.name}
                    </a>
                  </JerniaLink>
                ) : breadcrumb.onClick ? (
                  <button
                    onClick={breadcrumb.onClick}
                    className="whitespace-nowrap text-primary hover:text-text-800"
                  >
                    {breadcrumb.name}
                  </button>
                ) : (
                  <span className={classNames('whitespace-nowrap', className)}>
                    {breadcrumb.name}
                  </span>
                )}

                {!last && (
                  <ArrowRightIcon
                    size={5}
                    className="mx-1 flex-shrink-0 md:mx-2"
                  />
                )}
              </>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
}
