import Router from 'next/router';
import { useEffect, useState } from 'react';
import { useRef } from 'react';
import { UseSearchBoxProps, useSearchBox } from 'react-instantsearch';

import { SearchIcon } from '@jernia/shared/components/icons/icons';

import { useDebounce } from '@jernia/shared/hooks/debounce';

import { useIsRentalSearch, useRentalSearchParams } from '../../helpers/hooks';

export function SearchBox(props: UseSearchBoxProps) {
  const { query, refine } = useSearchBox(props);

  const inputRef = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState(query);
  const debouncedInputValue = useDebounce(inputValue, 500);
  const rentalSearchParams = useRentalSearchParams();
  const isRental = useIsRentalSearch();

  useEffect(() => {
    if (debouncedInputValue === query) return;

    if (!Router.asPath.startsWith('/search')) {
      Router.push(
        `/search?text=${debouncedInputValue}${
          isRental ? `&${rentalSearchParams}` : ''
        }`
      );
    }

    refine(debouncedInputValue);
  }, [debouncedInputValue, query, refine, rentalSearchParams, isRental]);

  useEffect(() => {
    setInputValue(query);
  }, [query]);

  return (
    <div className="flex items-center space-x-4 rounded border border-primary-500 bg-primary-100 p-4 focus-within:ring">
      <SearchIcon className="flex-none text-primary" size={6} />
      <input
        type="search"
        ref={inputRef}
        value={inputValue}
        onChange={(event) => {
          const value = event.target.value;
          setInputValue(value);
        }}
        placeholder="Søk etter vare"
        className="w-full bg-transparent appearance-none focus:outline-none"
      />
    </div>
  );
}
