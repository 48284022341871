import { groupBy, sortBy } from 'lodash';
import React, { useMemo } from 'react';
import { useState } from 'react';

import { Button } from '@jernia/shared/components/button/button';
import { LoaderFadeIcon } from '@jernia/shared/components/icons/icons';
import { Modal } from '@jernia/shared/components/modal/modal';
import { RadioInput } from '@jernia/shared/components/radio-input/radio-input';

import { useStores } from '@jernia/shared/state/auth';

import type { PointOfService } from '@jernia/shared/types/rest/stores';

type Props = {
  isOpen: boolean;
  onChange: (store: PointOfService) => void;
  onClose: () => void;
  initialSelectedStoreId?: string;
};

const regions: Record<string, string> = {
  '03': 'Oslo',
  '11': 'Rogaland',
  '15': 'Møre og Romsdal',
  '18': 'Nordland',
  '30': 'Viken',
  '34': 'Innlandet',
  '38': 'Vestfold og Telemark',
  '42': 'Agder',
  '46': 'Vestland',
  '50': 'Trøndelag',
  '54': 'Troms og Finnmark',
};

export function StoreSelectModal({
  isOpen,
  onClose,
  onChange,
  initialSelectedStoreId,
}: Props) {
  const stores = useStores();

  const [selectedStoreId, setSelectedStoreId] = useState<string | undefined>(
    initialSelectedStoreId
  );

  const storesByRegion = useMemo(() => {
    return sortBy(
      Object.entries(
        groupBy(stores.data, (store) => {
          return store.address.region?.isocode;
        })
      ).map(([regionCode, stores]) => {
        return {
          regionCode: regionCode ?? '-1',
          name: regions[regionCode] ?? 'Øvrige',
          stores,
        };
      }),
      'name'
    );
  }, [stores.data]);

  return (
    <Modal
      header="Velg Jerniabutikk"
      isOpen={isOpen}
      onRequestClose={onClose}
      footer={
        <Button
          kind="primary"
          size="large"
          className="w-full"
          onClick={() => {
            const store = stores.data?.find(
              (store) => store.vismaStoreId === selectedStoreId
            );
            if (store) {
              onChange(store);
            }
          }}
        >
          Velg din Jerniabutikk
        </Button>
      }
    >
      <div className="space-y-4">
        {stores.isLoading ? (
          <div className="flex justify-center py-4">
            <LoaderFadeIcon size={8} className="animate-spin" />
          </div>
        ) : null}
        {storesByRegion.map((region) => {
          return (
            <div key={region.regionCode} className="">
              <h3 className="mb-4 text-xl font-semibold">{region.name}</h3>
              <div className="divide-y divide-neutral-500">
                {region.stores.map((store, i) => {
                  return (
                    <div key={i} className="py-4">
                      <RadioInput
                        label={store.name}
                        checked={store.vismaStoreId === selectedStoreId}
                        onChange={() => setSelectedStoreId(store.vismaStoreId)}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </Modal>
  );
}
