import classNames from 'classnames';
import classnames from 'classnames';
import { formatUrl } from 'next/dist/shared/lib/router/utils/format-url';
import Link from 'next/link';
import type { LinkProps as NextLinkProps } from 'next/link';
import React from 'react';

type BaseProps = {
  className?: string;
  kind?:
    | 'success'
    | 'primary'
    | 'primary-medium'
    | 'primary-light'
    | 'ghost'
    | 'outline'
    | 'outline-dark'
    | 'danger'
    | 'danger-ghost'
    | 'vipps'
    | 'rental'
    | 'native';
  size?: 'extra-small' | 'small' | 'medium' | 'large';
  children: React.ReactNode;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  isDummy?: boolean;
};

export type ButtonProps = BaseProps & {
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
} & React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >;

function getClassName({
  className,
  kind,
  size,
}: {
  className: ButtonProps['className'];
  size: ButtonProps['size'];
  kind: ButtonProps['kind'];
}) {
  const isFullWidth = className?.includes('w-full');

  return classnames(
    className,
    'rounded flex items-center justify-center justify-items-center text-center',
    'disabled:!bg-neutral-700 disabled:!textwhite leading-none',

    !className?.includes('font-') ? 'font-semibold' : undefined,

    size === 'extra-small' && isFullWidth ? 'px-1.5' : undefined,
    size === 'small' && isFullWidth ? 'px-2' : undefined,
    size === 'medium' && isFullWidth ? 'px-2' : undefined,
    size === 'large' && isFullWidth ? 'px-2' : undefined,

    size === 'extra-small' && !isFullWidth ? 'px-3' : undefined,
    size === 'small' && !isFullWidth ? 'px-4' : undefined,
    size === 'medium' && !isFullWidth ? 'px-6' : undefined,
    size === 'large' && !isFullWidth ? 'px-8' : undefined,

    {
      'h-7 text-xs': size === 'extra-small',
      'py-2 text-xs': size === 'small',
      'py-3 text-sm': size === 'medium',
      'py-4': size === 'large',

      'bg-primary text-white hover:bg-primary-800 disabled:text-white':
        kind === 'primary',
      'bg-primary-500 text-primary-800 hover:bg-primary-300 disabled:text-white focus:bg-primary-300':
        kind === 'primary-light',
      'bg-primary-700 hover:bg-primary-300 hover:text-primary-800 disabled:text-white focus:bg-primary-300 focus:text-primary':
        kind === 'primary-medium',
      'bg-primary-100 hover:bg-primary-300 text-primary border-bg disabled:text-white':
        kind === 'ghost',
      'bg-white text-primary border border-neutral-500 disabled:text-white':
        kind === 'outline',
      'bg-transparent text-primary border border-primary-700 disabled:text-white':
        kind === 'outline-dark',
      'bg-success text-white hover:bg-success-800': kind === 'success',
      'bg-danger text-white': kind === 'danger',
      'bg-danger-100 text-danger': kind === 'danger-ghost',
      'bg-brand-vipps text-white': kind === 'vipps',
      'bg-transparent rounded-none hover:text-primary-700': kind === 'native',
      'bg-rent-700 text-white hover:bg-rent-800': kind === 'rental',
    }
  );
}

function renderChildren(
  size: BaseProps['size'],
  leftIcon: BaseProps['leftIcon'],
  children: BaseProps['children'],
  rightIcon: BaseProps['rightIcon']
) {
  return (
    <>
      {leftIcon ? (
        <span
          className={classNames({
            'mr-3': Boolean(children) && size === 'large',
            'mr-2': Boolean(children) && size === 'medium',
            'mr-1':
              Boolean(children) && (size === 'small' || size === 'extra-small'),
          })}
        >
          {leftIcon}
        </span>
      ) : null}
      {children}
      {rightIcon ? (
        <span
          className={classNames({
            'ml-3': Boolean(children) && size === 'large',
            'ml-2': Boolean(children) && size === 'medium',
            'ml-1':
              Boolean(children) && (size === 'small' || size === 'extra-small'),
          })}
        >
          {rightIcon}
        </span>
      ) : null}
    </>
  );
}

export const Button = ({
  kind = 'primary',
  size = 'large',
  disabled,
  className,
  children,
  leftIcon,
  rightIcon,
  onClick,
  isDummy = false,
  ...rest
}: ButtonProps) => {
  if (isDummy) {
    return (
      <span className={getClassName({ kind, size, className })}>
        {renderChildren(size, leftIcon, children, rightIcon)}
      </span>
    );
  }

  return (
    <button
      className={getClassName({ kind, size, className })}
      onClick={onClick}
      disabled={disabled}
      {...rest}
    >
      {renderChildren(size, leftIcon, children, rightIcon)}
    </button>
  );
};

type LinkProps = BaseProps & {
  href: NextLinkProps['href'];
  shallow?: NextLinkProps['shallow'];
  disabled?: boolean;
  useNextLink?: boolean;
};

export function ButtonLink({
  kind = 'primary',
  size = 'medium',
  useNextLink = true,
  leftIcon,
  rightIcon,
  disabled,
  className,
  children,
  shallow,
  href,
}: LinkProps) {
  if (disabled) {
    return (
      <Button
        disabled
        kind={kind}
        size={size}
        className={className}
        leftIcon={leftIcon}
        rightIcon={rightIcon}
      >
        {children}
      </Button>
    );
  }

  if (useNextLink) {
    return (
      <Link
        href={href}
        shallow={shallow}
        className={getClassName({ kind, size, className })}
      >
        {renderChildren(size, leftIcon, children, rightIcon)}
      </Link>
    );
  }

  const hrefString = typeof href === 'string' ? href : formatUrl(href);
  return (
    <a href={hrefString} className={getClassName({ kind, size, className })}>
      {renderChildren(size, leftIcon, children, rightIcon)}
    </a>
  );
}

export default Button;
