import classNames from 'classnames';
import { motion } from 'framer-motion';
import React, { useEffect, useRef } from 'react';
import ReactModal from 'react-modal';

import { useBreakpoint } from '@jernia/shared/hooks/breakpoint-hooks';

export type DrawerProps = {
  position?: 'left' | 'right';
  closeButton?: boolean;
  isOpen: boolean;
  onRequestClose?: () => void;
  onAfterClose?: () => void;
  className?: string;
  overlayClassName?: string;
  children: React.ReactChild;
};

export const Drawer = ({
  position = 'left',
  children,
  isOpen,
  className,
  onRequestClose,
}: DrawerProps) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      if (isOpen) {
        document.body.style.overflow = 'unset';
      }
    };
  }, [isOpen]);

  const isLg = useBreakpoint('lg');
  const containerRef = useRef<HTMLDivElement>(null);

  // Set container height based on the available screen real estate.
  // In some cases, phone browser UI can overlap the drawer's content.
  function handleOnAfterOpen() {
    if (!containerRef.current || window == undefined) return;

    const newHeight = isLg ? `100vh` : `${window.innerHeight}px`;
    containerRef.current.style.maxHeight = newHeight;
  }

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      onAfterOpen={handleOnAfterOpen}
      parentSelector={() => {
        return document.getElementById('next-modals') ?? document.body;
      }}
      overlayClassName={{
        base: classNames(
          'bg-neutral-800 bg-opacity-60 fixed top-0 left-0 w-full h-full transition-opacity duration-300 flex items-center z-50 opacity-0',
          position === 'right' ? 'justify-end' : 'justify-start'
        ),
        afterOpen: '!opacity-100',
        beforeClose: '!opacity-0',
      }}
      className={{
        base: `
          ${position === 'right' ? 'translate-x-full' : '-translate-x-full'}
          fixed
          h-full
          max-h-full
          w-[85vw]
          transform
          bg-white
          shadow-xl
          outline-none
          transition-all
          duration-300
          sm:max-w-sm
          ${className ? className : ''}
        `,
        afterOpen: isOpen ? '!translate-x-0' : '',
        beforeClose: `${
          position === 'right' ? 'translate-x-full' : '-translate-x-full'
        }`,
      }}
      closeTimeoutMS={300}
    >
      <div ref={containerRef} className="max-h-screen w-full overflow-y-auto">
        {/**
         * Hack to get animation to properly work in Safari. I have no clue
         * why this works, but without this it won't transition in, only
         * transition out on close. ¯\_(ツ)_/¯
         */}
        <motion.div
          aria-hidden
          variants={{
            active: {
              display: 'block',
            },
          }}
          animate={'active'}
        />
        {children}
      </div>
    </ReactModal>
  );
};

export default Drawer;
