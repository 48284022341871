import { useQuery } from 'react-query';

import { Facets, buildQueryString } from '../helpers/solr';
import { config } from '../lib/config';
import { SolrProductResult } from '../types/solr';

async function fetchFromSolr(searchQuery: string): Promise<SolrProductResult> {
  const response = await fetch(`${config.solrApiPrefix}?${searchQuery}`);
  return response.json();
}

export function useSolrProductQuery(productId: string) {
  const query = buildQueryString({
    codes: [productId],
  });

  return useQuery(['solr/product', productId], async () => {
    const result = await fetchFromSolr(query);
    return result.results[0] ?? null;
  });
}

export function useSolrSearch(
  {
    query = '',
    codes,
    sort = 'relevance',
    page = 0,
    facets = {},
    enabled = true,
  }: {
    codes?: string[];
    query?: string;
    sort?: string;
    page?: number;
    facets?: Facets;
    enabled?: boolean;
  },
  initialResult?: SolrProductResult
) {
  const searchQuery = buildQueryString({ query, sort, page, facets, codes });
  return useQuery<SolrProductResult>(
    ['solr', searchQuery],
    async () => {
      return fetchFromSolr(searchQuery);
    },
    {
      initialData: initialResult,
      keepPreviousData: true,
      enabled,
    }
  );
}
