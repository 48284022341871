import algoliasearch from 'algoliasearch/lite';

import { UserGroup } from '@jernia/shared/types/user';

const jerniaEnv = process.env.NEXT_PUBLIC_JERNIA_ENV;

export const APP_ID = '1482JF2WTF';
export const API_KEY = '7314e3ff27e6820522489cbcbc5d78bc';

function getRentalOrdersIndexName() {
  switch (jerniaEnv) {
    case 'production':
      return 'prod_jernia_rental_order_index';

    default:
    case 'self-checkout-test':
    case 'test':
    case 'jernia.no-test':
      return 'test_jernia_rental_order_index';
  }
}

function getProductIndexName() {
  switch (jerniaEnv) {
    case 'production':
    case 'self-checkout-prod':
    case 'jernia.no':
      return 'prod_jernia_product_index';

    default:
    case 'self-checkout-test':
    case 'test':
    case 'jernia.no-test':
      return 'test_jernia_product_index';

    case 'jernia.no-localhost':
      return 'local_jernia_product_index';
  }
}

function getCompleteProductIndexName() {
  switch (jerniaEnv) {
    case 'production':
    case 'self-checkout-prod':
    case 'jernia.no':
      return 'prod_jernia_complete_product_index';

    default:
    case 'self-checkout-test':
    case 'test':
    case 'jernia.no-test':
      return 'test_jernia_complete_product_index';

    case 'jernia.no-localhost':
      return 'local_jernia_complete_product_index';
  }
}

function getContentIndexName() {
  switch (jerniaEnv) {
    case 'production':
    case 'self-checkout-prod':
    case 'jernia.no':
      return 'prod_jernia_content_index';

    default:
    case 'self-checkout-test':
    case 'test':
    case 'jernia.no-test':
      return 'test_jernia_content_index';

    case 'jernia.no-localhost':
      return 'local_jernia_content_index';
  }
}

function getTicketIndexName() {
  switch (jerniaEnv) {
    case 'production':
      return 'prod_jernia_ticket_index';

    case 'self-checkout-test':
    case 'test':
    case 'jernia.no-test':
      return 'test_jernia_ticket_index';

    default:
      return 'test_jernia_ticket_index';
  }
}

function getOrderIndexName() {
  switch (jerniaEnv) {
    case 'production':
      return 'prod_jernia_order_index';

    case 'self-checkout-test':
    case 'test':
    case 'jernia.no-test':
      return 'test_jernia_order_index';

    default:
      return 'test_jernia_order_index';
  }
}

function getColorIndexName() {
  switch (jerniaEnv) {
    case 'production':
    case 'jernia.no':
      return 'prod_jernia_paint_color_index';

    case 'self-checkout-test':
    case 'test':
    case 'jernia.no-test':
      return 'test_jernia_paint_color_index';

    default:
      return 'test_jernia_paint_color_index';
  }
}

export const RENTAL_ORDER_INDEX_NAME = getRentalOrdersIndexName();
export const PRODUCT_INDEX_NAME = getProductIndexName();
export const COMPLETE_PRODUCT_INDEX_NAME = getCompleteProductIndexName();
export const CONTENT_INDEX_NAME = getContentIndexName();
export const TICKET_INDEX_NAME = getTicketIndexName();
export const ORDER_INDEX_NAME = getOrderIndexName();
export const COLOR_INDEX_NAME = getColorIndexName();

export type AlgoliaSortItem = {
  value: string;
  label: string;
  url: string;
};

export function getProductSortIndicies({
  userGroup,
}: {
  userGroup: UserGroup;
}): AlgoliaSortItem[] {
  const priceIndexNameMap: Record<UserGroup, string> = {
    anonymous: 'price',
    customerclub: 'club_price',
    b2b: 'b2b_price',
    employee: 'employee_price',
  };

  return [
    { value: PRODUCT_INDEX_NAME, label: 'Relevanse', url: 'relevanse' },
    {
      value: `${PRODUCT_INDEX_NAME}__${priceIndexNameMap[userGroup]}_asc`,
      label: 'Pris (lav - høy)',
      url: 'lavpris',
    },
    {
      value: `${PRODUCT_INDEX_NAME}__${priceIndexNameMap[userGroup]}_desc`,
      label: 'Pris (høy - lav)',
      url: 'høypris',
    },
    {
      value: `${PRODUCT_INDEX_NAME}__discount`,
      label: 'Størst rabatt',
      url: 'rabatt',
    },
  ];
}

export const CONTENT_SORT_INDICES: AlgoliaSortItem[] = [
  { value: CONTENT_INDEX_NAME, label: 'Relevanse', url: 'relevanse' },
  {
    value: `${CONTENT_INDEX_NAME}__newest`,
    label: 'Nyeste',
    url: 'nyeste',
  },
];

export const TICKET_SORT_INDICES: AlgoliaSortItem[] = [
  { value: TICKET_INDEX_NAME, label: 'Relevanse', url: 'relevanse' },
  { value: `${TICKET_INDEX_NAME}__newest`, label: 'Nyeste', url: 'nyeste' },
  {
    value: `${TICKET_INDEX_NAME}__oldest`,
    label: 'Eldste',
    url: 'Eldste',
  },
];

export const ORDER_SORT_INDICES: AlgoliaSortItem[] = [
  { value: ORDER_INDEX_NAME, label: 'Relevanse', url: 'relevanse' },
  { value: `${ORDER_INDEX_NAME}__newest`, label: 'Nyeste', url: 'nyeste' },
  {
    value: `${ORDER_INDEX_NAME}__oldest`,
    label: 'Eldste',
    url: 'Eldste',
  },
];

export function getProductFilters({ userGroup }: { userGroup: UserGroup }) {
  const priceFilterMap: Record<UserGroup, string> = {
    anonymous: 'price.b2ccustomergroup.active',
    customerclub: 'price.customerclubgroup.active',
    b2b: 'price.b2bcustomergroup.active',
    employee: 'price.employeecustomergroup.active',
  };

  return [
    {
      key: 'brandName',
      type: 'refinementList',
      urlName: 'merkenavn',
    },
    {
      key: 'inStock',
      type: 'menu',
      urlName: 'lager',
    },
    {
      key: 'inRentalStock',
      type: 'menu',
      urlName: 'utleielager',
    },
    {
      key: 'employeeRating',
      type: 'refinementList',
      urlName: 'Kvalitetskategori',
    },
    {
      key: priceFilterMap[userGroup],
      type: 'range',
      urlName: 'pris',
    },
    {
      key: 'categoryNameHierarchy.lvl0',
      type: 'hierarchicalMenu',
      urlName: 'kategori',
    },
    {
      key: 'rating',
      type: 'range',
      urlName: 'vurdering',
    },
    {
      key: 'isOnSale',
      type: 'toggle',
      urlName: 'salg',
    },
    {
      key: 'isCentralStock',
      type: 'toggle',
      urlName: 'nettlager',
    },
    {
      key: 'isRentable',
      type: 'toggle',
      urlName: 'utleie',
    },

    // articles
    {
      key: 'tags.name',
      type: 'refinementList',
      urlName: 'emne',
    },
  ];
}

export function getColorFilters() {
  return [
    {
      key: 'palettes.code',
      type: 'menu',
      urlName: 'fargekart',
    },
    {
      key: 'tones.code',
      type: 'refinementList',
      urlName: 'fargetoner',
    },
    {
      key: 'applicationAreas',
      type: 'refinementList',
      urlName: 'omrader',
    },
  ];
}

export const SearchClient = algoliasearch(APP_ID, API_KEY);
