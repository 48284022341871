import { useQuery } from 'react-query';

import { useSolrSearch } from '@jernia/shared/hooks/solr';

import { Placement, RecommendedProduct, RichRelevanceResult } from './types';

type RichRelevanceQuery = {
  sessionId?: string;
  userId?: string;
  placements?: string;
  productId?: string;
  chi?: string;
  rcs?: string;
  atcid?: string;
};

export function useRichRelevance({
  sessionId = '',
  userId = '',
  placements = [],
  productIds = [],
  categoryIds = [],
  rcs = '',
  atcid,
}: {
  sessionId?: string;
  userId?: string;
  placements?: string[];
  productIds?: string[];
  categoryIds?: string[];
  rcs?: string;
  atcid?: string;
}) {
  const productId = productIds.join('|');
  const chi = categoryIds.join('|');
  const placementsJoined = placements.join('|');

  let queryParams: RichRelevanceQuery = {
    sessionId,
    userId,
    placements: placementsJoined,
    productId,
    chi,
    rcs,
  };

  if (atcid) {
    queryParams = { ...queryParams, atcid };
  }

  const query = JSON.stringify(queryParams);

  return useQuery<unknown, unknown, RichRelevanceResult>(
    ['richrelevance', query],
    async () => {
      const response = await fetch(
        `${process.env.NEXT_PUBLIC_API_BASE}/api/rich-relevance`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: query,
        }
      );

      return await response.json();
    }
  );
}

export function mapProductIdsOfPlacement(
  placement?: Placement | null
): string[] {
  if (placement && Array.isArray(placement.recommendedProducts)) {
    return placement.recommendedProducts.map((product) => product.id);
  }

  return [];
}

export function usePlacement(placement: Placement) {
  const productCodes =
    placement.recommendedProducts.map(
      (product: RecommendedProduct) => product.id
    ) ?? [];

  return {
    title: placement.strategyMessage ?? '',
    rrProducts: placement.recommendedProducts ?? [],
    productsQuery: useSolrSearch({
      codes: productCodes,
      enabled: productCodes?.length > 0,
    }),
  };
}
